<template>
  <b-card

      class="card-transaction"
      no-body
  >
    <b-card-header>
      <b-card-title>Últimas Solicitudes</b-card-title>
    </b-card-header>

    <b-card-body>
      <div
          v-for="solicitud in solicitudesData"
          :key="solicitud.frmId"
          class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
                rounded
                size="42"
                variant="primary"
            >
              <feather-icon
                  size="18"
                  icon="CheckSquareIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ solicitud.empNombres }} {{ solicitud.empApellidos }}
            </h6>
            <small> Solicitado : {{ getFormatDate(solicitud.frmFecha) }} </small>
          </b-media-body>
        </b-media>
        <div
            class="text-success"
        >
          {{ getFormatDateTime(solicitud.frmFechaLimite) }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import light from 'echarts/src/theme/light'
//import { primarySort } from 'vue-good-table/src/components/utils/sort'
import { resolveFormatoFecha, resolveFormatoFechaHora } from '@/modules/common/utils'

export default {
  computed: {
    light() {
      return light
    }
  },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },

  data() {
    return {
      solicitudesData: []
    }
  },

  methods: {
    ...mapActions('dashboard-module', ['getSolicitudesUltimas']),

    getFormatDate( date ){
      return resolveFormatoFecha(date)
    },

    getFormatDateTime( date ){
      return resolveFormatoFechaHora(date)
    },

    //primarySort,



    async loadData(){
      this.solicitudesData = await  this.getSolicitudesUltimas() //2023-07-01 | fg | obtener todas las solicitudes creacion/modificacionn
    }
  },

  async created() {
    await this.loadData()
  }



}
</script>
