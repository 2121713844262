<template>
  <b-card
      no-body
      class="card-revenue-budget"
  >
    <b-row class="mx-0">



      <!-- data section -->

        <b-col
            md="12"
            class="revenue-report-wrapper"
        >


          <div class="d-sm-flex justify-content-between align-items-center mb-3">
            <h4 class="card-title mb-50 mb-sm-0">
              Solicitudes por Mes
            </h4>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center mr-2">
                <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
                <span>Enviadas</span>
              </div>
              <div class="d-flex align-items-center ml-75">
                <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
                <span>Finalizadas</span>
              </div>
            </div>
          </div>

          <!-- loading state -->
          <template v-if="isLoading">
            <div class="text-center">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </template>


          <template v-else>
            <!-- chart -->
            <vue-apex-charts
                id="revenue-report-chart"
                type="bar"
                height="230"
                :options="revenueReport.chartOptions"
                :series="revenue.budgetChart.series"
            />
          </template>

        </b-col>


    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton, BSpinner
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    BSpinner
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {

      isLoading: false,

      revenue: {
        revenueReport: {
          series: [
            {
              name: 'Enviadas',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: 'Finalizadas',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        budgetChart: {
          series: [
            {
              data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
            },
          ],
        },
      },

      revenue_report: {},
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dic'],
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: '17%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
          },
        },
      },
      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            enabled: false,
          },
        },
      },
    }
  },


  methods: {

    ...mapActions('dashboard-module', ['getSolicitudesMes']),

    async loadData(){

      try {

        this.isLoading = true
        this.revenue.budgetChart.series = await  this.getSolicitudesMes() //2023-07-01 | fg | procesar todas las solicitudes creacion/modificacion

      }  catch (error) {

      } finally {
        this.isLoading = false
      }


    }
  },
  async created() {
    await this.loadData()
  }


}
</script>
