<template>

  <section id="dashboard-solicitante">

  <b-row class="match-height">
    <b-col
        xl="12"
        md="12"
    >
      <dashboard-solicitante-estadisticas/>

    </b-col>


  </b-row>

    <b-row class="match-height">

      <!-- Revenue Report Card -->
      <b-col lg="12">
        <dashboard-solicitante-anual/>
      </b-col>
      <!--/ Revenue Report Card -->

    </b-row>


    <b-row>

      <b-col md="6">

        <dashboard-solicitante-completadas/>

      </b-col>

      <!-- Company Table Card -->
      <b-col md="6">

        <dashboard-solicitante-solicitudes-recientes/>

      </b-col>
      <!--/ Company Table Card -->

    </b-row>

  </section>

</template>

<script>

import { BRow, BCol } from 'bootstrap-vue'

import DashboardSolicitanteEstadisticas from '@/modules/solicitante/views/dashboard/DashboardSolicitanteEstadisticas.vue'
import DashboardSolicitanteCompletadas from '@/modules/solicitante/views/dashboard/DashboardSolicitanteCompletadas.vue'
import DashboardSolicitanteAnual from '@/modules/solicitante/views/dashboard/DashboardSolicitanteAnual.vue'
import DashboardSolicitanteSolicitudesRecientes from '@/modules/solicitante/views/dashboard/DashboardSolicitanteSolicitudesRecientes.vue'
import DashboardSolicitanteSolicitudes from '@/modules/solicitante/views/dashboard/DashboardSolicitanteSolicitudes.vue'
import DashboardSolicitanteSolicitudesGrafica from '@/modules/solicitante/views/dashboard/DashboardSolicitanteSolicitudesGrafica.vue'

export default {
  name: 'Dashboard',
  components: {
    DashboardSolicitanteSolicitudesGrafica,
    DashboardSolicitanteSolicitudes,
    DashboardSolicitanteSolicitudesRecientes,
    BRow,
    BCol,
    DashboardSolicitanteCompletadas,
    DashboardSolicitanteEstadisticas,
    DashboardSolicitanteAnual
  },

  data() {
    return {
      data: {},

      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Customers',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '1.423k',
          subtitle: 'Products',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '$9745',
          subtitle: 'Revenue',
          customClass: '',
        },
      ],

    }
  },

}
</script>

<style scoped>

</style>



